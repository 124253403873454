window.addEventListener("turbolinks:load", () => {


	createOptionsLink = document.getElementById("create_add_another_option");
	if(createOptionsLink)
		createOptionsLink.onclick = addOptionToPoll;
		
	editOptionsLink = document.getElementById("edit_add_another_option");
	if(editOptionsLink)
		editOptionsLink.onclick = addOptionToPoll;
	
});

function addOptionToPoll(event) {
	optionsList = document.getElementById("options");
	optionListItem = document.createElement("li");
	optionListItem.setAttribute('class', 'option');
	
	optionCount = optionsList.getElementsByTagName("li").length;
	optionIndex = optionCount;
	optionLabel = optionCount + 1;
	
	option = '<label for="poll_options_attributes_' + optionIndex + '_name">Option ' + optionLabel + ':</label> ' + 
			 '<input type="text" name="poll[options_attributes][' + optionIndex + '][name]" id="poll_options_attributes_' + optionIndex + '_name">';
	
	optionListItem.innerHTML = option;
	optionsList.appendChild(optionListItem);
	return false;
}

